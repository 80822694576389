import React, {useEffect, useState} from 'react';
import {beforeTravel} from "../../config/endpoints";

const BeforeToTravel = () =>{

    const [beforeTravelData, setBeforeTravelData] = useState([]);
    const fetchFooterAPI = async () => {
            try {
                const res = await fetch(beforeTravel);
                const data = await res.json();
                setBeforeTravelData(data);

            } catch (error) {
                console.error(error);
            }
        };
        useEffect(() => {
            fetchFooterAPI();
        }, []);

        return <div className="about-section mb-5 mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 align-self-center">
                        <div className="section-title mb-lg-0 text-justify">
                           <div dangerouslySetInnerHTML={{__html: beforeTravelData.beforeTravel}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

}

export default BeforeToTravel;
