import {useEffect, useState} from "react";
import {banners} from "../../config/endpoints";


const Banner = () => {
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchAPIData();
	}, []);
	const fetchAPIData = async () => {
		try {
			const res = await fetch(banners);
			const data = await res.json();
			setData(data);
		} catch (error) {
			console.error(error);
		}
	};


	return<>
	<div className="banner-area viaje-go-top">
			  <div className="banner-slider">
				  {
					  data.data && data.data.map((banner, key) => {
						  return (
							  <div key={key} className="banner-slider-item ">
								  <img src={banner.image} alt=""/>
							  </div>
						  )
					  })
				  }

			  </div>

			</div>
   </>
}

export default Banner
