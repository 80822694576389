import React, {useEffect, useState} from 'react';
import PageHeader from './global-components/page-header';
import {Link, useParams } from "react-router-dom";
import {getPackageByType} from "../config/endpoints";

const PackageListPage = () => {

    const { slug } = useParams();
    const [packagesData, setPackagesData] = useState(null);

    useEffect(() => {
        fetchPackageData();
    }, [slug]);

    const fetchPackageData = async () => {
        try {
            const res = await fetch(`${getPackageByType}/${slug}`);
            const data = await res.json();
            setPackagesData(data);
        } catch (error) {
            console.error(error);
        }
    };

    if (!packagesData) {
         return<div className="spinner-border text-info text-center" role="status">
            <span className="sr-only"></span>
        </div>
    }


    return<div>

        <PageHeader headertitle={packagesData.name} />
        <div className="package-area pd-top-108 mg-bottom-92">
            <div className="container">
                            <div className="row">
                                {packagesData.packages.map((value,key)=>{
                                    return(
                                        <div className="col-xl-4 col-sm-6" key={key}>
                                            <div
                                                className="single-package-card wow animated fadeInUp"
                                                data-wow-duration="0.4s"
                                                data-wow-delay="0.1s"
                                            >
                                                <div className="thumb">
                                                    <img src={value.image} alt="img"/>
                                                </div>
                                                <div className="details">
                                                    <h3>{value.name}</h3>
                                                    <ul className="package-meta">
                                                        <li className="tp-price-meta">
                                                            <p>
                                                                <i className="fa fa-clock-o"/>
                                                            </p>
                                                            <h2>Duration</h2>
                                                            <h6>{value.days} Days</h6>
                                                        </li>
                                                        <li className="tp-price-meta">
                                                            <p>
                                                                <i className="fa fa-users"/>
                                                            </p>
                                                            <h2>Room Type</h2>
                                                            <h6>{value.room_type}</h6>
                                                        </li>
                                                        <li className="tp-price-meta">
                                                            <p>
                                                                <i className="fa fa-tag"/>
                                                            </p>
                                                            <h2>Price</h2>
                                                            <h6>£ {value.package_price}</h6>
                                                        </li>
                                                    </ul>
                                                    <div className="text-center">
                                                        <Link style={{color:'#033F3F', background:'#DB7E46',fontSize:'18px'}} className="btn btn-warning " to={`/package/${value.slug}`}>View Package Detail</Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
            </div>
        </div>
    </div>
}

export default PackageListPage

