import React from 'react';
import PageHeader from './global-components/page-header';
import Faq from './section-components/faq';


const FaqPage = () => {
    return <div>
        <PageHeader headertitle="FAQs"  />
        <Faq />
    </div>
}

export default FaqPage

