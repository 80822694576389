import React from 'react';

import GetQuote from "./get-quote";


  const TourDetails = ({packageDetail}) => {
        let publicUrl = process.env.PUBLIC_URL+'/'




    return	<div className="tour-details-area mg-top--70">
              <div className="tour-details-gallery">
                <div className="container-bg bg-dark-blue">
                  <div className="container">
                    <div className="gallery-filter-area row">
                      <div className="gallery-sizer col-1" />
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-md-5 col-sm-6 mb-10">
                        <div className="tp-gallery-item-img">
                          <div className="thumbnails">
                            <img src={publicUrl+"assets/img/tour-details/665-x-422.jpg"} alt="image" />
                          </div>
                        </div>
                      </div>
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-md-3 col-sm-6">
                        <div className="tp-gallery-item-img">
                          <a href="#" data-effect="mfp-zoom-in">
                            <img src={publicUrl+"assets/img/tour-details/387-x-422.jpg"} alt="image" />
                          </a>
                        </div>
                      </div>
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-lg-2 col-md-4 col-sm-6">
                        <div className="tp-gallery-item-img">
                          <a href="#" data-effect="mfp-zoom-in">
                            <img src={publicUrl+"assets/img/tour-details/248-x-196-1.jpg"} alt="image" />
                          </a>
                        </div>
                      </div>
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-lg-2 col-md-4 col-sm-6">
                        <div className="tp-gallery-item-img">
                          <a href="#" data-effect="mfp-zoom-in">
                            <img src={publicUrl+"assets/img/tour-details/248-x-196-2.jpg"} alt="image" />
                          </a>
                        </div>
                      </div>
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-lg-2 col-md-4 col-sm-6">
                        <div className="tp-gallery-item-img">
                          <a href="#" data-effect="mfp-zoom-in">
                            <img src={publicUrl+"assets/img/tour-details/248-x-196-3.jpg"} alt="image" />
                          </a>
                        </div>
                      </div>
                      {/* gallery-item */}
                      <div className="tp-gallery-item col-lg-2 col-md-4 col-sm-6">
                        <div className="tp-gallery-item-img">
                          <a href="#" data-effect="mfp-zoom-in">
                            <img src={publicUrl+"assets/img/tour-details/248-x-196-4.jpg"} alt="image" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="text-center mt-3">
                          <h3 style={{color:"#F3941E", fontSize:"35px"}}>Package Details</h3>
                        </div>
                        <div className="details">
                          <table className="table ">
                            <thead>
                            <tr>
                                <th scope="col"><h4>Package Price</h4></th>
                              <th scope="col">
                                <div className="tp-price-meta">
                                  <h2  style={{fontSize:'22px'}}>£ {packageDetail.package_price} </h2>
                                </div>

                              </th>
                              </tr>
                            <tr>
                              <th scope="col"><h4>Makkah Hotel Name</h4></th>
                              <th scope="col"><h4>{packageDetail.makkah_hotel_name}</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Night in Makkah</h4></th>
                              <th scope="col"><h4>{packageDetail.package_night_makkah} Nights</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Madina Hotel Name</h4></th>
                              <th scope="col"><h4>{packageDetail.madina_hotel_name}</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Night in Madina</h4></th>
                              <th scope="col"><h4>{packageDetail.package_night_madina} Nights</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Room Type</h4></th>
                              <th scope="col"><h4>{packageDetail.room_type}</h4></th>
                            </tr>
                             <tr>
                              <th scope="col"><h4>Transport</h4></th>
                              <th scope="col"><h4>{packageDetail.transport}</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Breakfast</h4></th>
                              <th scope="col"><h4>{packageDetail.breakfast}</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Visa</h4></th>
                              <th scope="col"><h4>{packageDetail.visa}</h4></th>
                            </tr>
                            <tr>
                              <th scope="col"><h4>Ziyarat</h4></th>
                              <th scope="col"><h4>{packageDetail.ziyarat}</h4></th>
                            </tr>

                            </thead>

                          </table>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12">
                        <div className="tp-main-search" style={{background:"transparent",paddingTop:0}}>
                          <GetQuote />
                        </div>
                      </div>   
                    </div>
                  </div>
                </div>
              </div>

            </div>
}

export default TourDetails;
