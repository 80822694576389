
const Terms = () => {
     const siteName = 'Umrah Insights';

        let publicUrl = process.env.PUBLIC_URL+'/'


    return	<div className="faq-page-area pd-top-110 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="section-title mb-0">
                        <h2 className="title text-center">Terms & Conditions</h2>

                          <p>
                              The agreement is between you and {siteName}. Please read all the terms and conditions.
                              You agree with all the terms and conditions when you visit our site. And for doing business with
                              us, you need to agree with all our terms.
                              If you do not agree with our terms and conditions, then you may not proceed with booking your
                              tour with {siteName}.
                          </p>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <ul className="nav nav-tabs tp-tabs">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#tabs_1">General contract and user agreement</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_2">Conditions on which we deliver the booking</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_3">Cancellation</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_4">Conditions for flight booking</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_5">Payment Terms</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_6">Passport, Visa, and Health requirement</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_7">Refund Policy</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_8">In General terms and conditions</a>
                            </li>

                          </ul>
                        </div>
                        <div className="col-md-7">
                          <div className="tab-content faq-tab-content" style={{backgroundImage: 'url('+publicUrl+'assets/img/others/12.png)'}}>
                            <div className="tab-pane fade show active" id="tabs_1">
                              <div className="faq-details text-justify">
                                <p >
                                    When you book a package, then you are buying a product or service from the site that  {siteName} offers. We make it in the receipt, and a verification email will be sent to you. All our
                                    products and services are subject to availability. We negotiate with third-parties for hotel
                                    bookings, car rentals, flight booking, tour packages, and other travel services. And we will
                                    mention when a product or service is unavailable.
                                    </p>
                                  <p >
                                    We advise all customers to avail of the travel insurance because there are circumstances when
                                    Neither {siteName} nor our travel suppliers accept the liability.
                                </p>
                                  <h6>Travel Suppliers</h6>
                                  <p>
                                      {siteName} is acquiring travel services for you as an agent. You have to agree with all the
                                      terms and conditions of travel suppliers and operators. In this case, the contract is between the
                                      travel supplier and you. {siteName} only arranges the services for you. We are not liable for
                                      the products and services of third-party suppliers.
                                  </p>
                                  <h6>Travel Supplier Conditions</h6>
                                  <p>
                                      The conditions such as flight delay, hotel reservation, travel accessory’s qualities are beyond our
                                      control. That’s why you have to accept the situation as such. You have to agree with travel
                                      supplier conditions, which will include payment procedure, default liability, cancellation,
                                      refunds, and changes in booking.
                                  </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_2">
                              <div className="faq-details text-justify">
                                <p>
                                    You can opt for either e-tickets or paper tickets. However, the availability of your chosen ticket
                                    will be subject to the airline. Some airlines do not issue e-tickets.
                                    We will deliver your paper tickets through the mail. So, you must double-check your mailing
                                    address while entering it on our site. We will not be responsible for the loss of tickets due to the
                                    wrong mailing address.
                                    </p>
                                  <p>
                                    We will deliver your e-tickets via email. So, you must double-check your email address while
                                    entering it on our site. We will not be responsible for the loss of tickets due to the wrong email
                                    address.
                                </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_3">
                              <div className="faq-details text-justify">
                               <p>
                                   If you want to cancel or modify your booking, then you must do it by telephoning our customer
                                   service. It will also be subject to airline rules and regulations. And you have to bear all the costs.
                                   For canceling or modifying your hotel reservation, you must inform us before 7 days of
                                   reservation date. For car rentals, the terms and conditions of travel suppliers will apply. That’s
                                   why it will be beyond our control, and you have to adjust to the supplier’s terms regarding
                                   cancellation or modification.
                               </p>
                                  <h6>Notes</h6>
                                  <ul className="text-white">
                                      <li>The deposit for flight tickets is totally nonrefundable. We are not responsible for refund if you cancel your booking after getting flight tickets.</li>
                                      <li>Your passport must have 6 months before expiration.</li>
                                      <li>The Visa is issued by the Saudi Embassy, and we are not responsible if your visa Application got rejected due to any reason.</li>
                                      <li>The visa cost will vary according to the Saudi embassy rules.</li>
                                      <li>If your Visa got rejected, then you can apply for flight and hotel reservation cancellation.</li>
                                      <li>In that case, you will get a full refund.</li>
                                      <li>The hotels that we offer are all subject to availability. That’s why you will get a
                                          reservation upon confirmation from the hotel.
                                      </li>
                                      <li>We will get you a similar hotel if your reservation is canceled from the hotel due to any
                                          circumstance.
                                      </li>

                                  </ul>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_4">
                              <div className="faq-details">
                               <p>
                                   We will get you a flight as you request if flights are available. If there are no flights available
                                   according to your preference, then we will show you the options. You can choose any option
                                   from our list.
                                   Sometimes direct flights are not available. That’s why you have to choose from all the other
                                   available options.
                               </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_5">
                              <div className="faq-details text-justify">
                                  <p>
                                      You have to pay the full amount at the time of flight booking. For other payments, you have
                                      three options, which are the following.
                                  </p>
                                  <ul className="text-white">
                                      <li>Full amount deposit at the time of booking.</li>
                                      <li>Some deposit at the time of booking with the remainder payable at checkout.</li>
                                      <li>Full amount deposit at check out.</li>
                                  </ul>
                                  <p>
                                      You also have to pay the charges to travel suppliers if applicable according to their terms and
                                      conditions.
                                  </p>
                                  <h6>Your Financial Protection</h6>
                                  <p>
                                      All your payments are protected under the Civil Aviation Authority scheme known as ATOL (Air Travellers Organisers License). Our ATOL number is 74904.
                                  </p>
                                  <p>
                                      Many of the flights and flight-inclusive travel arrangements we arrange on your behalf are financially protected by the ATOL scheme. But ATOL protection does not apply to all travel and travel services. Please ask us to confirm what protection may apply to your booking. If you do not receive an ATOL Certificate then the booking will not be ATOL protected. If you do receive an ATOL Certificate but all the parts of your trip are not listed on it, those parts not listed will not be ATOL protected. For more information about financial protection and the ATOL Certificate go to atol.org.uk/ATOLCertificate.
                                  </p>
                                  <p>
                                      When you buy an ATOL protected fight or flight inclusive travel through us you will receive an ATOL Certificate. This lists what is financially protected, where you can get information on what this means for you and who to contact if things go wrong.
                                  </p>
                                  <p>
                                      If your booking (or part of it) is ATOL protected, we, or the travel service provider identified on your ATOL Certificate, will provide you with the booking service and arrange the travel services as listed on the ATOL Certificate (or a suitable alternative). In some cases, where neither we nor the travel service provider are able to do so for reasons of insolvency, an alternative ATOL holder may provide you with the travel services you have bought or a suitable alternative (at no extra cost to you). You agree to accept that in those circumstances the alternative ATOL holder will perform those obligations and you agree to pay any money outstanding to be paid by you under your contract to that alternative ATOL holder. However, you also agree that in some cases it will not be possible to appoint an alternative ATOL holder, in which case you will be entitled to make a claim under the ATOL scheme (or your credit card issuer where applicable).
                                  </p>
                                  <p>
                                      If we, or the travel service provider identified on your ATOL certificate, are unable to arrange or provide the services listed (or a suitable alternative, through an alternative ATOL holder or otherwise) for reasons of insolvency, the Trustees of the Air Travel Trust may make a payment to (or confer a benefit on) you under the ATOL scheme. You agree that in return for such a payment or benefit you assign absolutely to those Trustees any claims which you have or may have arising out of or relating to the non-provision of the services, including any claim against us, the travel agent (or your credit card issuer where applicable). You also agree that any such claims may be reassigned to another body, if that other body has paid sums you have claimed under the ATOL scheme.
                                  </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_6">
                              <div className="faq-details text-justify">
                                  <p>
                                  You will submit your passport to us, and we will do our process. The Visa is sanctioned by the
                                  Saudi Embassy, and we do not have a role other than submitting your passport to the Embassy.
                                  We will deliver your passport back to you after the issuance of your Visa. If the Embassy asks special
                                  documents, then we will inform you. And you have to provide the relevant details.
                                  If the Embassy requires a health certificate, then you have to provide it from a registered medical
                                  practitioner. If your application is rejected due to some reason, then we are not liable. We will
                                  charge you for the visa service.
                                  </p>
                                  <p>
                                  You have to inform us about your health requirements. So, we can make special arrangements if
                                  required.
                                  </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_7">
                              <div className="faq-details text-justify">
                                  <p>
                                      We are not able to refund the full payment in any case like Pandemic
                                  </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_8">
                              <div className="faq-details text-justify">
                                  <p>
                                      {siteName} does not accept legal responsibility if there is a failure to deliver your booking
                                      or part of your booking, which is not a fault of us.
                                      </p>
                                  <p>
                                      {siteName} is legally responsible for direct loss, which will be equal to the maximum
                                      amount of your booking. We will not compensate you more than your deposited amount to us.
                                      {siteName} is not liable for any indirect loss or consequential loss during your tour.
                                      User obligation terms
                                  </p>
                                  <h6>You agree to following obligations without limitations.</h6>
                                  <ul className="text-white">
                                      <li>
                                          You are responsible for all financial transactions. We do not compel you. It is your
                                          choice.
                                      </li>
                                      <li>You must be 18 years old and have legal capacity.</li>
                                      <li>You testify that all information provided by you is correct and true.</li>
                                      <li>You must not use this site for false, fraudulent, and fake bookings.</li>
                                      <li>We prohibit all threatening, pornographic, defaming, political, derogatory, and racist
                                          material.</li>
                                      <li>You must not use the content of this site other than personal use.</li>
                                  </ul>
                                  <h6>Denial of Service</h6>
                                  <p>{siteName} has the right to deny the service to anyone due to any reason.</p>
                                  <h6>Copyrights and Trademarks</h6>
                                  <p>{siteName} reserves the rights of all the content on this site.</p>
                                  <h6>Privacy Policy</h6>
                                  <p>You agree to use your personal information by {siteName}.</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

}

export default Terms;