import React from 'react';
import PageHeader from './global-components/page-header';
import Contact from './section-components/contact';

const ContactPage = () => {
    return <div>
        <PageHeader headertitle="Contact Us"  />
        <Contact />
    </div>
}

export default ContactPage

