import React, { Component } from 'react';


class Faq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'


    return	<div className="faq-page-area pd-top-110">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="section-title mb-0">
                        <h2 className="title text-center">Frequently Asked Questions</h2>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <ul className="nav nav-tabs tp-tabs">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#tabs_1">Who are we?</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_2">How Do Our Agents Work?</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_3">Are you planning to perform the hajj in 2024?</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tabs_4">Why Book Umrah 2024 Packages with Us?</a>
                            </li>

                          </ul>
                        </div>
                        <div className="col-md-7">
                          <div className="tab-content faq-tab-content" style={{backgroundImage: 'url('+publicUrl+'assets/img/others/12.png)'}}>
                            <div className="tab-pane fade show active" id="tabs_1">
                              <div className="faq-details">
                                <p className="text-justify">
                                 Umrah Insights offer Muslim brothers and sisters Umrah and Hajj services at affordable rates. We facilitate our customers with all services to have a comfortable journey to fulfill their spiritual duties. From flight reservation, to hotel booking and to travel between Makkah and Medina we arrange all things. We need customer satisfaction and comfort during the trips, so we manage all things required while performing Umrah or Hajj.
                                </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_2">
                              <div className="faq-details">
                                <p className="text-justify">
                                  Once you contact us, our team of dedicated agents immediately provide our brochure with all Umrah deals. We offer 3, 4, and 5 star Umrah packages plus, provide you different flight opportunities. You can tell us about the flight you want to take, the number of days you want to stay there plus the type of hotel. All your stay expenses will be arranged in your budget limit. You don’t need to worry about hotel accommodation. We have contact with all hotel management and our agents are working in KSA too. We reserve the room for you that you need. We will provide you with the nearest and neat accommodation in both Mecca and Madinah. We provide Umrah packages with flights. We book airline tickets direct from Heathrow Airport London to Jeddah or from London to Mecca. We reserved tickets according to your requirements. Feel free to contact us and get more details on the Umrah deals we offer. Either you want a Family Umrah package or you want to travel alone. We offer all types of Umrah packages for our beloved customers.
                                </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_3">
                              <div className="faq-details">
                               <p className="text-justify">
                                 Want to make your tour free of hassles? Then Umrah Insights will meet your expectations. We offer low-cost hajj packages that you can afford. Moreover, we facilitate our customers with different 5 stars 14 days non-shifting hajj packages so you can perform Hajj with comfort. Get prepare yourself for Umrah or hajj. At our platform, you will get economy Umrah packages. Moreover, apply for hajj 2024 and stay stress-free as all preparation from ticket reservations, hotel accommodation, and traveling between cities is our responsibility now. For further Umrah services and packages check out our deals given below. Contact us, ask for any query, we will resolve you because your comfort is our comfort. It is our main preference to help you perform holy obligations with peace and comfort. Therefore, we offer secured and consistent Umrah and hajj packages. Take services from Umrah Insights UK and get luxurious yet cheap accommodation throughout your voyage.
                               </p>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="tabs_4">
                              <div className="faq-details">
                                <h6>Customer Protection</h6>
                               <p>
                                 Umrah Insights offers complete customer protection for its umrah travel services. When you book your Economy Umrah Package with us, be assured to receive the best quality services in town. Our work ethic revolves around transparent business policies and clear communication. We give complete control to our customers for their Umrah travel and accommodation management while providing professional supervision. That’s why our agency holds a reputable and distinctive position in the UK Umrah travel market.
                               </p>
                                <h6>
                                  ATOL Protection
                                </h6>
                                <p>
                                  As an ATOL-registered company, all of our economy 4-star Umrah packages as well as luxury ones remain ATOL protected. ATOL (Air Travel Organizer’s License) is a financial protection scheme backed by the UK government. The scheme not only safeguards consumers who book package holidays and/or flights from the UK but also offers sufficient cover for it. So, peace of mind is ensured for our customers as we don’t leave them alone at any step of their foreign trip.
                                </p>
                                <h6>
                                  IATA Protection
                                </h6>
                                <p>
                                  Umrah Insights offers IATA (International Air Transport Association) protection for all its international flights booked for Umrah travel. As a member company of this international trade organization that represents airlines across the globe, the set of rules and regulations as well as standards set for quality and safe air travel is fulfilled at every stage by us. Consequently, ensuring a safe, comfortable, and hassle-free air travel experience for the clients.
                                </p>
                                <h6>
                                  We are available 24/7 For You
                                </h6>
                                <p>
                                  If you have any query related to our work methodologies, need to make some suggestions, require assistance in your Umrah travel booking phase or want to make any complaint, our customer support staff is available 24/7 for your assistance. Get in touch with our representatives and have your matters resolved immediately!
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
        }
}

export default Faq
