import React  from 'react';
import { Link } from 'react-router-dom';


const BlogV2  = ({blogsData}) => {


	console.log(blogsData);

    return<div className="blog-list-area pd-top-120 viaje-go-top">
		  <div className="container">
		    <div className="row justify-content-center">
				{
					blogsData && blogsData.map((blog,key)=> {
						return <div className="col-lg-4 col-md-6" key={key}>
								<div className="single-blog">
									<div className="thumb">
										<img src={blog.blog_thumbnail} alt="blog" />
									</div>
									<div className="single-blog-details">
										<p className="date">{blog.published_date}</p>
										<h4 className="title"><Link to={`/blog/${blog.slug}`}>{blog.title}</Link></h4>
										<Link className="btn-read-more" to={`/blog/${blog.slug}`}><span>Read More<i className="la la-arrow-right" /></span></Link>
									</div>
								</div>
							</div>
					})
				}


		    </div>
		  </div>
		</div>
}

export default BlogV2;
