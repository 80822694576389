import React, { Component } from 'react';
import {app_name} from "../../config/endpoints";


class IntroV3 extends Component {

    render() {
    return  <div className="intro-area pd-top-108">
			  <div className="container">
			    <div className="section-title text-lg-center text-left">
			      <h2 className="title">About <span > {app_name}</span> </h2>
			    </div>
			  </div>
			</div>
        }
}

export default IntroV3
