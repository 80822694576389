import React, {useEffect, useState} from 'react';
import PageHeader from './global-components/page-header';
import BlogList from './blog-components/blog-v2';
import Subscribe from './section-components/subscribe';
import {blogs} from "../config/endpoints";

const BlogV2 = () => {

    const [blogsData, setBlogsData] = useState([]);
    useEffect(() => {
        fetchAPIData();
    }, []);
    const fetchAPIData = async () => {
        try {
            const res = await fetch(blogs);
            const data = await res.json();
            setBlogsData(data);

        } catch (error) {
            console.error(error);
        }
    };
    if (!blogsData) {
        return <div>Loading...</div>;
    }
    return <div>

        <PageHeader headertitle="Blog"  />
        <BlogList blogsData={blogsData.data} />
        <Subscribe />

    </div>
}

export default BlogV2

