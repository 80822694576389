import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {setting} from "../../config/endpoints";


let publicUrl = process.env.PUBLIC_URL + '/';
const minscript = document.createElement('script');
minscript.async = true;
minscript.src = publicUrl + 'assets/js/main.js';
document.body.appendChild(minscript);

const FooterV1 = () => {

	const [footerData, setFooterData] = useState([]);
	const fetchFooterAPI = async () => {
		try {
			const res = await fetch(setting);
			const data = await res.json();
			setFooterData(data);
			// console.log('API call',footer)
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		fetchFooterAPI();
	}, []);

	let publicUrl = process.env.PUBLIC_URL + '/';

	return <>
		<footer className="footer-area" style={{ backgroundImage: `url(${publicUrl}assets/img/bg/2.png)` }}>
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="footer-widget widget">
							<div className="about_us_widget">
								<Link to="/" className="footer-logo">
									<img src={publicUrl + 'assets/img/logo-white.png'} alt="footer logo" />
								</Link>
								<p>
									{footerData.about_company}
								</p>
								<ul className="social-icon">
									<li>
										<a className="facebook" href={footerData.facebook} target="_blank"><i className="fa fa-facebook" /></a>
									</li>
									<li>
										<a className="twitter" href={footerData.twitter} target="_blank"><i className="fa fa-twitter" /></a>
									</li>
									<li>
										<a className="pinterest" href={footerData.instagram} target="_blank"><i className="fa fa-instagram" /></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="footer-widget widget">
							<h4 className="widget-title">Quick Link</h4>
							<ul className="widget_nav_menu  viaje-go-top">
								<li><Link to="/">Home</Link></li>
								<li><Link to="/before-you-travel">Before You Travel</Link></li>
								<li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>
								<li><Link to="/privacy-policy">Privacy Policy</Link></li>
								<li><Link to="/faqs">FAQs</Link></li>
								<li><Link to="/about">About Us</Link></li>
								<li><Link to="/contact">Contact Us</Link></li>

							</ul>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="footer-widget widget ">
							<div className="widget-contact">
								<h4 className="widget-title">Contact us</h4>
								<p>
									<i className="fa fa-map-marker" />
									<span>  {footerData.address}    </span>
								</p>
								<p>
									<i className="fa fa-map-marker" />
									<span> 7404 Av Champagneur, Montréal, QC H3N 2J9   </span>
								</p>
								<p className="location">
									<i className="fa fa-envelope-o" />
									<span> {footerData.email}  </span>
								</p>
								<p className="telephone">
									<i className="fa fa-phone base-color" />
									<span>
                                     {footerData.phone}
				              </span>
								</p>
								<p className="telephone">
									<i className="fa fa-phone base-color" />
									<span>
                                     +1514-999-8409
				              </span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-inner">
				<div className="copyright-text">
					© Umrah Insights{new Date().getFullYear()} All rights reserved.
				</div>
			</div>
		</footer>

	</>

}

export default FooterV1;
