import React, {useState} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import {quote} from "../../config/endpoints";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/form-card-desgin.css";




const GetQuote = () =>  {

	const [getQuote, setGetQuote] = useState({
		name:"",
		email:"",
		phone:"",
		departure_airport:"",
		arrival_airport:"",
		arrival_date:"",
		makkah_nights:"",
		madinah_nights:"",
		rooms:"",
		accommodation:"",
		adult:"",
		child:"",
		infant:"",
		stay_duration:""
	});

	const [errors, setErrors] = useState({});

	const [loading, setLoading]=useState(false)

	const validateForm = () => {
        let errors = {};
		let isValid = true;

		if (!getQuote.name) {
			isValid = false;
			errors.name = 'Name is required';
		}

		if (!getQuote.email) {
			isValid = false;
			errors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(getQuote.email)) {
			isValid = false;
			errors.email = 'Please enter a valid email';
		}
		if (!getQuote.phone) {
			isValid = false;
			errors.phone = 'Phone is required';
		}

		if (!getQuote.arrival_date) {
			isValid = false;
			errors.arrival_date = 'Departure Date is required';
		}

		if (!getQuote.departure_airport) {
			isValid = false;
			errors.departure_airport = 'Select Departure Airport';
		}

		if (!getQuote.arrival_airport) {
			isValid = false;
			errors.arrival_airport = 'Select Arrival Airport';
		}
		if (!getQuote.stay_duration) {
			isValid = false;
			errors.stay_duration = 'Stay Duration is required';
		}

		if (!getQuote.madinah_nights) {
			isValid = false;
			errors.madinah_nights = 'Select Number of Madinah Nights';
		}
		if (!getQuote.makkah_nights) {
			isValid = false;
			errors.makkah_nights = 'Select Number of Makkah Nights';
		}
      if (!getQuote.rooms) {
			isValid = false;
			errors.rooms = 'Select Number of Rooms';
		}
		if (!getQuote.accommodation) {
			isValid = false;
			errors.accommodation = 'Select Number of Accommodation';
		}
		if (!getQuote.adult) {
			isValid = false;
			errors.adult = 'Select Number of Adult';
		}

		if (!getQuote.child) {
			isValid = false;
			errors.child = 'Select Number of Child';
		}

		if (!getQuote.infant) {
			isValid = false;
			errors.infant = 'Select Number of Infant';
		}
		setErrors(errors);

		return isValid;
	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validateForm()) {
			setLoading(true);
			try{
				const res = await axios.post(quote,getQuote)

				if (res.data.status === '200') {

					Swal.fire({
						position: 'center',
						icon: 'success',
						title: res.data.message,
						showConfirmButton: false,
						timer: 1500
					})

					setGetQuote({
						name: "",
						email: "",
						phone: "",
						departure_airport: "",
						arrival_airport: "",
						arrival_date:"",
						makkah_nights: "",
						madinah_nights: "",
						rooms:"",
						accommodation:"",
						adult:"",
						child:"",
						infant:"",
						stay_duration:""
					})
					setErrors({});
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}


		}
	};

	const departure_airport = ['London (LHR)','London (LGW)','Manchester','Birmingham','Aberdeen','Edinburgh','Glasgow','Others'];
    const arrival_airport = ['jadida','madinah','others'];
    const counting = ['1','2','3','4','5','6','7','8','9','10'];
	const accommodation = ['3 Star','4 Star','5 Star'];

	let publicUrl = process.env.PUBLIC_URL + '/';
    return  <div className="search-area tp-main-search-area mt-0 pd-top-50 viaje-go-top" id="main_search" >
			  <div className="container">
			    <div className="tp-main-search tp-main-search-2" style={{ backgroundImage: `url(${publicUrl}assets/img/bg/2.png)` }}>
					<div className="text-center mb-4">
						<h3 style={{color:'#DB7E46'}}>Find Your Best Umrah Package</h3>
						<p style={{color:'#fcfcfc'}}>Find the world's largest collection of Hajj & Umrah Packages</p>
					</div>

					<form onSubmit={handleSubmit}>
			      <div className="row">
					  <div className="col-lg-3 col-md-4">
						  <select
							  value={getQuote.departure_airport}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, departure_airport: event.target.value })
							  }
							  className="form-control"
						  >
							  <option>Departure Airport</option>
							  {
								  departure_airport.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }
						  </select>
						  {errors.departure_airport && <span className="text-danger" style={{fontSize:'12px'}}>{errors.departure_airport}</span>}
					  </div>
					  <div className="col-lg-3 col-md-4">
						  <select
							  className="form-control"
							  value={getQuote.arrival_airport}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, arrival_airport: event.target.value })
							  }
						  >
								  <option>Arrival Airport</option>
							  {
								  arrival_airport.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }

						  </select>
						  {errors.departure_airport && <span className="text-danger" style={{fontSize:'12px'}}>{errors.departure_airport}</span>}
					  </div>
					  <div className="col-lg-3 col-md-4">
						  <select
							  value={getQuote.makkah_nights}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, makkah_nights: event.target.value })
							  }
							  className="form-control"
							  >
							  <option>Makkah Nights</option>
							  {
								  counting.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }
						  </select>
						  {errors.makkah_nights && <span className="text-danger" style={{fontSize:'12px'}}>{errors.makkah_nights}</span>}
					  </div>
					  <div className="col-lg-3 col-md-4">
						  <select
							  value={getQuote.madinah_nights}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, madinah_nights: event.target.value })
							  }
							  className="form-control">
							  <option>Madinah Nights</option>
							  {
								  counting.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }
						  </select>
						  {errors.madinah_nights && <span className="text-danger" style={{fontSize:'12px'}}>{errors.madinah_nights}</span>}
					  </div>
				  </div>
					  <div className="row mt-3">
					  <div className="col-lg-3 col-md-4">
						  <select
							  value={getQuote.rooms}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, rooms: event.target.value })
							  }
							  className="form-control">
							  <option>Rooms</option>
							  {
								  counting.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }
						  </select>
						  {errors.rooms && <span className="text-danger" style={{fontSize:'12px'}}>{errors.rooms}</span>}
					  </div>
					  <div className="col-lg-3 col-md-4">
						  <DatePicker
							  placeholderText="Arrival Date"
							  className="form-control w-100"
							  selected={getQuote.arrival_date}
							  onChange={(date) =>  setGetQuote({ ...getQuote, arrival_date:date })}
						  />
						  {errors.arrival_date && <span className="text-danger" style={{fontSize:'12px'}}>{errors.arrival_date}</span>}
					  </div>
					  <div className="col-lg-3 col-md-4">
						  <select
							  value={getQuote.accommodation}
							  onChange={(event) =>
								  setGetQuote({ ...getQuote, accommodation: event.target.value })
							  }
							  className="form-control">
							  <option>Accommodation</option>
							  {
								  accommodation.map((item, index) => (
									  <option key={index} value={item}>{item}</option>
								  ))
							  }
						  </select>
						  {errors.accommodation && <span className="text-danger" style={{fontSize:'12px'}}>{errors.accommodation}</span>}
					  </div>
						  <div className="col-lg-3 col-md-4">
							  <input className="form-control"
									 type="text"
									 name="name"
									 placeholder="Name"
									 value={getQuote.name}
									 onChange={(event) =>
										 setGetQuote({ ...getQuote, name: event.target.value })
									 }
							  />
							  {errors.name && <span className="text-danger" style={{fontSize:'12px'}}>{errors.name}</span>}
						  </div>
				     </div>
					  <div className="row mt-3">

			        <div className="col-lg-3 col-md-4">

			            <input className="form-control"
							   type="text"
							   name="email"
							   placeholder="Email"
							   value={getQuote.email}
							   onChange={(event) =>
								   setGetQuote({ ...getQuote, email: event.target.value })
							   }

						/>

						{errors.email && <span className="text-danger" style={{fontSize:'12px'}}>{errors.email}</span>}

					</div>
					<div className="col-lg-3 col-md-4">

							  <input className="form-control"
									 type="text"
									 name="phone"
									 placeholder="Phone"
									 value={getQuote.phone}
									 onChange={(event) =>
										 setGetQuote({ ...getQuote, phone: event.target.value })
									 }

							  />

						{errors.phone && <span className="text-danger" style={{fontSize:'12px'}}>{errors.phone}</span>}
					  </div>
					<div className="col-lg-3 col-md-4">

							  <select
								  value={getQuote.adult}
								  onChange={(event) =>
									  setGetQuote({ ...getQuote, adult: event.target.value })
								  }
								  className="form-control">
								  <option>Adult</option>
								  {
									  counting.map((item, index) => (
										  <option key={index} value={item}>{item}</option>
									  ))
								  }
							  </select>

							  {errors.adult && <span className="text-danger" style={{fontSize:'12px'}}>{errors.adult}</span>}

						  </div>
						  <div className="col-lg-3 col-md-4">

							  <select
								  value={getQuote.child}
								  onChange={(event) =>
									  setGetQuote({ ...getQuote, child: event.target.value })
								  }
								  className="form-control">
								  <option>Child</option>
								  {
									  counting.map((item, index) => (
										  <option key={index} value={item}>{item}</option>
									  ))
								  }
							  </select>

							  {errors.child && <span className="text-danger" style={{fontSize:'12px'}}>{errors.child}</span>}

						  </div>
				  </div>
					<div className="row mt-3">


						<div className="col-lg-3 col-md-4">

								<select
									value={getQuote.infant}
									onChange={(event) =>
										setGetQuote({ ...getQuote, infant: event.target.value })
									}
									className="form-control">
								<option>Infant</option>
									{
										counting.map((item, index) => (
											<option key={index} value={item}>{item}</option>
										))
									}
								</select>

							{errors.infant && <span className="text-danger" style={{fontSize:'12px'}}>{errors.infant}</span>}

						</div>
						<div className="col-lg-3 col-md-4">

								<input className="form-control"
									   type="text"
									   name="stay_duration"
									   placeholder="Stay Duration"
									   value={getQuote.stay_duration}
									   onChange={(event) =>
										   setGetQuote({ ...getQuote, stay_duration: event.target.value })
									   }
								/>

							{errors.stay_duration && <span className="text-danger" style={{fontSize:'12px'}}>{errors.stay_duration}</span>}

						</div>

					</div>
						<div className="row mt-3 text-center">

							<div className="col-lg-3 col-md-4 offset-9">
								<button className="btn btn-yellow" type="submit">
									{
										loading ? <i className="fa fa-spinner fa-spin" /> : "Get Quote"
									}
								</button>
							</div>
						</div>
					</form>
			      </div>
			    </div>
			  </div>

}

export default GetQuote;
