import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import {packages} from "../../config/endpoints";

const Package = () => {

	const [data, setData] = useState([]);
	useEffect(() => {
		fetchAPIData();
	}, []);
	const fetchAPIData = async () => {
		try {
			const res = await fetch(packages);
			const data = await res.json();
			setData(data);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="package-area pd-top-108 mg-bottom-92">
			<div className="container">
				{data.data && data.data.map((packageType, key) => {
					return (
						<div key={key}>
							<div className="row justify-content-center">
								<div className="col-xl-6 col-lg-8">
									<div className="section-title text-center">
										<h2 className="title wow animated fadeInUp" data-wow-duration="0.6s"
											data-wow-delay="0.1s">
											{packageType.name}
										</h2>
									</div>
								</div>
							</div>
							<div className="row">
								{packageType.packages.map((value,key)=>{
									return(
										<div className="col-xl-4 col-sm-6" key={key}>
											<div
												className="single-package-card wow animated fadeInUp"
												data-wow-duration="0.4s"
												data-wow-delay="0.1s"
											>
												<div className="thumb">
													<img src={value.image} alt="img"/>
												</div>
												<div className="details">
													<h3>{value.name}</h3>
													<ul className="package-meta">
														<li className="tp-price-meta">
															<p>
																<i className="fa fa-clock-o"/>
															</p>
															<h2>Duration</h2>
															<h6>{value.days} Days</h6>
														</li>
														<li className="tp-price-meta">
															<p>
																<i className="fa fa-users"/>
															</p>
															<h2>Room Type</h2>
															<h6>{value.room_type}</h6>
														</li>
														<li className="tp-price-meta">
															<p>
																<i className="fa fa-tag"/>
															</p>
															<h2>Price</h2>
															<h6>£ {value.package_price} </h6>
														</li>
													</ul>
													<div className="text-center">
														<Link style={{color:'#033F3F', background:'#DB7E46',fontSize:'18px'}} className="btn btn-warning " to={`/package/${value.slug}`}>View Package Detail</Link>
													</div>

												</div>
											</div>
										</div>
									);
								})}

							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Package;
