import React, {useEffect, useState} from 'react';
import {about} from "../../config/endpoints";


const About = () => {
    const [aboutUs, setAboutUsData] = useState([]);
    const fetchFooterAPI = async () => {
        try {
            const res = await fetch(about);
            const data = await res.json();
            setAboutUsData(data);

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
    fetchFooterAPI();
}, []);


    return	<div className="about-section mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="section-title mb-lg-0 text-justify">
                      <h2 className="title">Lets Go Travel with Us</h2>
                        <div dangerouslySetInnerHTML={{__html: aboutUs.about_us}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

}

export default About
