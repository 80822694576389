import React from 'react';
import PageHeader from './global-components/page-header';
import Privacy from "./section-components/privacy";

const PrivacyPolicy = () => {
    return <div>
        <PageHeader headertitle="Privacy Policy"  />
        <Privacy />
    </div>
}

export default PrivacyPolicy

