import Sidebar from './sidebar';



const BlogDetails = ({blogData}) => {

	console.error(blogData.blog.title);
	return<div className="blog-details-area pd-top-120 viaje-go-top">
		<div className="container">
			<div className="row">
				<div className="col-lg-8">
					<div className="single-blog mb-0">
						<div className="thumb">
							<img src={blogData.blog.blog_image} alt="blog"/>
						</div>
						<div className="single-blog-details">
							<div className="d-flex justify-content-between">
								<p className="date mb-0"><strong>Author: </strong>{blogData.blog.author_name}</p>
								<p className="date mb-0"><strong>Published Date:</strong> {blogData.blog.published_date}</p>
							</div>

							<h3 className="title mt-4">{blogData.blog.title}</h3>
							<p className="content mb-0">
								<div dangerouslySetInnerHTML={{__html: blogData.blog.description}}/>
							</p>

						</div>
					</div>
				</div>
				<Sidebar relatedBlogs={blogData.recentBlog}/>
			</div>
		</div>
	</div>

}

export default BlogDetails;
