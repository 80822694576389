import React from 'react';
import { Link } from 'react-router-dom';



const Sidebar = ({relatedBlogs}) => {

    return (
      <div className="col-lg-4">
            <aside className="sidebar-area sidebar-area-4">

              <div className="widget widget-recent-post">
                <h2 className="widget-title">Recent Post</h2>
                <ul>
                  {
                      relatedBlogs && relatedBlogs.map((blog,key) => {
                        return <Link to={`/blog/${blog.slug}`}> <li key={key}>
                          <div className="media">
                            <img src={blog.blog_thumbnail} width={'100px'} alt="widget" />
                            <div className="media-body">
                              <span className="post-date">{blog.published_date}</span>
                              <h6 className="title">
                               {blog.title}
                              </h6>
                            </div>
                          </div>
                        </li>
                        </Link>
                      })
                  }
                </ul>
              </div>
            </aside>
          </div>
    )

}

export default Sidebar;
