import React, {useEffect, useState} from 'react';
import PageHeader from './global-components/page-header';
import TourDetails from './section-components/tour-details';
import { useParams } from 'react-router-dom';
import {packageDetail} from "../config/endpoints";

const PackageDetailsPage = () => {

        const { slug } = useParams();
        const [packageData, setPackageData] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setLoading(true);
            fetchPackageData();
        }, []);

        const fetchPackageData = async () => {
            try {
                const res = await fetch(`${packageDetail}/${slug}`);
                const data = await res.json();
                setPackageData(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

    console.log(packageData);
    if (!packageData) {
        return<div className="spinner-border text-center  text-info" role="status">
            <span className="sr-only"></span>
        </div>
    }

    return <div>
        {
            loading && <div className="spinner-border text-center text-info" role="status"/>
        }
        <PageHeader headertitle={`${packageData.name} Package Details`} />
        <TourDetails packageDetail = {packageData}/>
    </div>
}

export default PackageDetailsPage;

