import React, {useEffect, useState} from 'react';

import axios from "axios";
import Swal from "sweetalert2";
import {contact} from "../../config/endpoints";

const Contact = () =>{

  const [contactData, setContactData] = useState([]);

   const fetchFooterAPI = async () => {
      try {
          const res = await fetch(contact);
          const data = await res.json();
        setContactData(data);
          // console.log('API call',footer)
      } catch (error) {
          console.error(error);
      }
  };
  useEffect(() => {
      fetchFooterAPI();
  }, []);

        let publicUrl = process.env.PUBLIC_URL+'/'

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});


  console.log(errors)
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!name) {
      isValid = false;
      errors.name = 'Name is required';
    }

    if (!email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = 'Please enter a valid email';
    }
    if (!phone) {
      isValid = false;
      errors.phone = 'Phone is required';
    }
    if (!message) {
      isValid = false;
      errors.message = 'Type Your Message ';
    }




    setErrors(errors);

    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {

      const res = await axios.post(`https://dashboard.alsafwahtravels.co.uk/api/v1/contact-form`,{
        name:name,
        email:email,
        phone:phone,
        message:message,
      });

      if (res.data.status === '200') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })

        setName("");
        setEmail('');
        setPhone('');
        setMessage('');

        setErrors({});

      }
    }
  };


  return	<div>
              <div className="contact-area pd-top-108">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="section-title text-lg-center text-left">
                        <h2 className="title">Get In Touch!</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-5 offset-xl-1 col-lg-6">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/contact-us.jpg"} alt="img" />
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                      <form className="tp-form-wrap" onSubmit={handleSubmit}>
                        <div className="row">

                          <div className="col-md-6">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Name</span>
                              <input type="text"
                                     name="name"
                                     value={name}
                                     onChange={(e) => setName(e.target.value)}
                              />
                              {errors.name && <span className="text-danger" style={{fontSize:'12px'}}>{errors.name}</span>}
                            </label>

                          </div>
                          <div className="col-md-6">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Phone Number</span>
                              <input type="text"
                                     name="phone"
                                     value={phone}
                                     onChange={(e) => setPhone(e.target.value)}
                              />
                              {errors.phone && <span className="text-danger" style={{fontSize:'12px'}}>{errors.phone}</span>}
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Email</span>
                              <input type="text"
                                     name="email"
                                     value={email}
                                     onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && <span className="text-danger" style={{fontSize:'12px'}}>{errors.email}</span>}
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Message</span>
                              <textarea
                                  name="message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                              />
                              {errors.message && <span className="text-danger" style={{fontSize:'12px'}}>{errors.message}</span>}
                            </label>
                          </div>
                          <div className="col-12">
                            <input type="submit" className="btn btn-yellow" value="Send Message" />
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-info-area pd-top-120 mb-5">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 order-lg-12">

                      <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2378.6359048090076!2d-2.997464823869517!3d53.403452670449916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b212cf1fbce53%3A0xd6274a9996cd01b5!2s89d%2C%204%20Mann%20Island%2C%20Liverpool%20L3%201BP%2C%20UK!5e0!3m2!1sen!2s!4v1701799920549!5m2!1sen!2s"
                          width="600" height="450" style={{border:0}} allowFullScreen="" title="map" className="contact-map" loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"/>
                    </div>
                    <div className="col-xl-3 col-lg-4 order-lg-1">
                      <div className="contact-info bg-gray">
                        <p>
                          <i className="fa fa-map-marker" />
                          <span>{contactData.address}</span>
                        </p>
                        <p>
                          <i className="fa fa-map-marker" />
                          <span>7404 Av Champagneur, Montréal, QC H3N 2J9</span>
                        </p>
                        <p>
                          <i className="fa fa-clock-o" /> 
                          <span>Office Hour 9:00 to 7:00 Sunday 10:00 to 5:00</span>
                        </p>
                        <p>
                          <i className="fa fa-envelope" />
                          <span><a href={`mailto:${contactData.address}`}>{contactData.email}</a></span>
                        </p>
                        <p>
                          <i className="fa fa-phone" /> 
                          <span>
                            <a href={`tel:${contactData.phone}`}> {contactData.phone}</a>
                          </span>
                        </p>
                        <p>
                          <i className="fa fa-phone" />
                          <span>
                            <a href={`tel:+15149998409`}> +1514-999-8409</a>
                          </span>
                        </p>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

}

export default Contact;
