import React, {useEffect, useState} from 'react';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import Subscribe from './section-components/subscribe';
import {useParams} from "react-router-dom";
import {blogDetail} from "../config/endpoints";

const BlogDetailsPage = () => {
    const { slug } = useParams();
    const [blogData, setBlogData] = useState(null);
    useEffect(() => {
        fetchBlogDetailData();
    }, [slug]);

    const fetchBlogDetailData = async () => {
        try {
            const res = await fetch(`${blogDetail}/${slug}`);
            const data = await res.json();
            setBlogData(data);
        } catch (error) {
            console.error(error);
        }
    };


    if (!blogData) {
        return <div>Loading...</div>;
    }
    return <div>
        <PageHeader headertitle={blogData.data.blog.title}  />
        <BlogDetails blogData={blogData.data} />
        <Subscribe />

    </div>
}

export default BlogDetailsPage

