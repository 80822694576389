import {createBrowserRouter, Route} from "react-router-dom";
import Layout from "../components/Layout";
import Home_V3 from "../components/home-v3";
import AboutUs from "../components/about";
import PackageList from "../components/package-list";
import PackageDetailsPage from "../components/package-details";
import Faq from "../components/faq";
import BlogDetailsPage from "../components/blog-details";
import TermsAndConditions from "../components/terms-and-conditions";
import PrivacyPolicy from "../components/privacy-policy";
import Contact from "../components/contact";
import BeforeYouTravel from "../components/before-you-travel";
import Error from "../components/error";
import React from "react";
import Blogs from "../components/blogs";


const routes = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home_V3 />,
            },
            {
                path: "/about",
                element: <AboutUs />,
            },
            {
                path: "/packages/:slug",
                element: <PackageList />,
            },
            {
                path: "/package/:slug",
                element: <PackageDetailsPage />,
            },
            {
                path: "/faqs",
                element: <Faq />,
            },
            {
                path: "/blogs",
                element: <Blogs />,
            },
            {
                path: "/blog/:slug",
                element: <BlogDetailsPage />,
            },
            {
                path: "/terms-and-conditions",
                element: <TermsAndConditions />,
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "/contact",
                element: <Contact />,
            },
            {
                path: "/before-you-travel",
                element: <BeforeYouTravel />,
            },
            {
                path: "/error",
                element: <Error />,
            },

        ],
    },
])


export default routes;
