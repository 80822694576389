import React from 'react';
import PageHeader from './global-components/page-header';
import Terms from "./section-components/terms";

const TermsAndConditions = () => {
    return <div>
        <PageHeader headertitle="Terms & Conditions"  />
        <Terms />
    </div>
}

export default TermsAndConditions

