import React  from 'react';
import ReactDOM from 'react-dom';
import {RouterProvider} from "react-router-dom";

import routes from "./config/router.js";

ReactDOM.render(<RouterProvider router={routes} />, document.getElementById('viaje'));

// ReactDOM.createRoot(document.getElementById("viaje")).render(
// 	<RouterProvider router={routes} />
// );
/*
class Root extends Component {

    render() {
        return(
			<React.Fragment>
					<Routes>
						<Route>
	                    <Route exact path="/" component={HomeV3} />
	                    <Route path="/about" component={AboutUs} />
	                    <Route exact path="/packages/:slug" component={PackageList} />
	                    <Route exact path="/package/:slug" component={PackageDetailsPage} />
	                    <Route path="/faqs" component={Faq} />
	                    <Route path="/blogs" component={blogs} />
	                    <Route path="/blog/:slug" component={BlogDetailsPage} />
	                    <Route path="/terms-and-conditions" component={TermsAndConditions} />
	                    <Route path="/privacy-policy" component={PrivacyPolicy} />
	                    <Route path="/contact" component={Contact} />
	                    <Route path="/before-you-travel" component={BeforeYouTravel} />
	                    <Route path="/error" component={Error} />
						</Route>
					</Routes>
			</React.Fragment>
        )
    }
}

export default Root;

 ReactDOM.render(<Root />, document.getElementById('viaje'));
*/
