
import {Outlet} from "react-router-dom";
import Navbar from "../global-components/navbar";
import FooterV1 from "../global-components/footer";


const Index = () => {
    return <div>
        <Navbar />
        <Outlet/>
        <FooterV1 />
    </div>
}

export default Index
