import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {navBarContent} from "../../config/endpoints";


	const Navbar = () => {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'

		const [headerData, setHeaderData] = useState([]);
		useEffect(() => {
			fetchAPIData();
		}, []);
		const fetchAPIData = async () => {
			try {
				const res = await fetch(navBarContent);
				const data = await res.json();
				setHeaderData(data);
			} catch (error) {
				console.error(error);
			}
		};

        return (
            <nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">
			  <div className="container nav-container">
			    <div className="responsive-mobile-menu">
			      <div className="mobile-logo">
			        <Link to="/">
			          <img src={publicUrl+"assets/img/logo-white.png"} alt={ imgattr } />
			        </Link>
			      </div>
			      <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
			        <span className="navbar-toggle-icon">
			          <span className="line" />
			          <span className="line" />
			          <span className="line" />
			        </span>
			      </button>
			    </div>
			    <div className="collapse navbar-collapse" id="tp_main_menu">
			      <div className="logo-wrapper desktop-logo">
			        <Link to="/" className="main-logo">
			          <img src={publicUrl+"assets/img/logo-white.png"} alt="dark-logo" />
			        </Link>
			        <Link to="/" className="sticky-logo">
			          <img src={publicUrl+"assets/img/logo-dark.png"} alt="dark-logo" />
			        </Link>
			      </div>
			      <ul className="navbar-nav">
					  <li>
						  <Link to="/">Home</Link>
					  </li>

			        <li className="menu-item-has-children">
			          <a href="#">Packages</a>
			          <ul className="sub-menu">
						  {
							  headerData.data &&  headerData.data.map((value,key) => {
								  return (
									  <li key={key}>
										  <Link to={`/packages/${value.slug}`}>{value.name}</Link>
									  </li>
								  );
							  })
						  }
			          </ul>
			        </li>
					  <li>
						  <Link to="/before-you-travel">Before You Travel</Link>
					  </li>
					  <li>
						  <Link to="/blogs">Blogs</Link>

					  </li>
					  <li>
						  <Link to="/about">About Us</Link>
					  </li>
			        <li>
			          <Link to="/contact">Contact Us</Link>
			        </li>
			      </ul>
			    </div>
			  </div>
			</nav>
        )

	};


export default Navbar;
