import React from 'react';
import PageHeader from './global-components/page-header';
import Intro from './section-components/intro-v3';
import About from './section-components/about';

const AboutPage = () => {
    return <div>
        <PageHeader headertitle="About Us"  />
        <Intro />
        <About />
    </div>
}

export default AboutPage

