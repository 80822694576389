import React, {useEffect, useState} from 'react';
import {privacy} from "../../config/endpoints";


const Privacy = () => {

    const [policyData, setpolicyData] = useState([]);
    const fetchFooterAPI = async () => {
        try {
            const res = await fetch(privacy);
            const data = await res.json();
            setpolicyData(data);

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchFooterAPI();
    }, []);



    return <div className="faq-page-area pd-top-110 mb-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="section-title mb-0">
                        <h2 className="title text-center">Privacy Policy</h2>
                        <div dangerouslySetInnerHTML={{__html: policyData.privacy}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default Privacy
