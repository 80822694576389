
export const app_name = 'Umrah Insights';
export const url = 'https://dashboard.umrahinsights.com/api/v1';

export const banners = `${url}/banners`
export const about = `${url}/about-us`
export const beforeTravel = `${url}/before-travel`
export const blogs = `${url}/blogs`
export const blogDetail = `${url}/blog`
export const contact = `${url}/setting`
export const navBarContent = `${url}/header-data`
export const packages = `${url}/packages`
export const packageDetail = `${url}/get-package`
export const getPackageByType = `${url}/get-package-by-type`
export const quote = `${url}/quote`
export const privacy = `${url}/privacy`
export const setting = `${url}/setting`



