import React from 'react';
import PageHeader from './global-components/page-header';
import BeforeToTravel from "./section-components/beforeTravel";

const FaqPage = () => {

    return <div>
        <PageHeader headertitle="Before You Travel"  />
        <BeforeToTravel />

    </div>
}

export default FaqPage

