import Banner from './section-components/banner';
import Package from './section-components/package';
import Faq from "./section-components/faq";
import React from "react";
import GetQuote from "./section-components/get-quote";



const Home_V3 = () => {
    return <>
        <Banner />
        <GetQuote />
        <Package />
        <Faq />
    </>
}

export default Home_V3

